import { ofSeq, tryFind } from "../Server/.fable/fable-library.3.1.12/Map.js";
import { map2, defaultArgWith, defaultArg, bind } from "../Server/.fable/fable-library.3.1.12/Option.js";
import { tryParse } from "../Server/.fable/fable-library.3.1.12/Guid.js";
import { FSharpRef } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { navigatorShare, shareFileFromUrl, FileShare, navigatorCanShare, clipboard as clipboard_1, tryToOption } from "./Utils.fs.js";
import { tryParse as tryParse_1 } from "../Server/.fable/fable-library.3.1.12/Int32.js";
import { Msg, State, UrlState } from "./AppState.fs.js";
import { RouterModule_router, RouterModule_nav, RouterModule_urlSegments, RouterModule_encodeQueryString, RouterModule_encodeParts } from "../Server/.fable/Feliz.Router.3.7.0/Router.fs.js";
import { map, singleton, append, empty, delay, toList } from "../Server/.fable/fable-library.3.1.12/Seq.js";
import { ofArray, empty as empty_1, singleton as singleton_1 } from "../Server/.fable/fable-library.3.1.12/List.js";
import { ogImgDim, linkpreviewHeight, linkpreviewWidth, ogVideoDim, githubRepo, contactEmail, videoDim, defaultTextValue, siteName, oEmbedApiEndpoint, canonicalBaseUrl } from "./Config.fs.js";
import { imgAlt, imgSrc, linkpreviewAlt, linkpreviewSrc, vidSrc, CheckfaceSrc, shortCheckfaceSrcDesc } from "./Checkface.fs.js";
import { interpolate, printf, toText } from "../Server/.fable/fable-library.3.1.12/String.js";
import { toString, today } from "../Server/.fable/fable-library.3.1.12/Date.js";
import { Cmd_OfPromise_result, Cmd_OfPromise_either, Cmd_OfFunc_result, Cmd_none } from "../Server/.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { viewShareContent, ShareState, ShareMsg } from "./Share.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../Server/.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../Server/.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { equals } from "../Server/.fable/fable-library.3.1.12/Util.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../Server/.fable/Feliz.1.40.1/Interop.fs.js";
import { column } from "../Server/.fable/Fulma.2.9.0/Layouts/Column.fs.js";
import { MuiHelpers_createElement } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import SvgIcon from "@material-ui/core/SvgIcon";
import { logo, animatedLogo } from "./Logos.fs.js";
import { footer as footer_1 } from "../Server/.fable/Fulma.2.9.0/Layouts/Footer.fs.js";
import { Common_GenericOption } from "../Server/.fable/Fulma.2.9.0/Common.fs.js";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import GitHub from "@material-ui/icons/GitHub";
import { StyleImports_createMuiTheme_argsArray } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { fromFlatEntries } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Flatten.fs.js";
import { ThemeProvider } from "@material-ui/core/styles";
import { HookImports_useMediaQuery } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Hooks.fs.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import { renderBrowseFacesDialog, renderEncodeImageDialog, renderContent } from "./MorphForm.fs.js";
import { view as view_1 } from "./Explain.fs.js";
import { Helmet } from "react-helmet";
import { useReact_useEffectOnce_3A5B6456 } from "../Server/.fable/Feliz.1.40.1/React.fs.js";

export function parseUrl(path, query) {
    return new UrlState(tryFind("from_value", query), tryFind("to_value", query), bind((arg) => {
        let tupledArg;
        let outArg = "00000000-0000-0000-0000-000000000000";
        tupledArg = [tryParse(arg, new FSharpRef(() => outArg, (v) => {
            outArg = v;
        })), outArg];
        return tryToOption(tupledArg[0], tupledArg[1]);
    }, tryFind("from_guid", query)), bind((arg_1) => {
        let tupledArg_1;
        let outArg_1 = "00000000-0000-0000-0000-000000000000";
        tupledArg_1 = [tryParse(arg_1, new FSharpRef(() => outArg_1, (v_1) => {
            outArg_1 = v_1;
        })), outArg_1];
        return tryToOption(tupledArg_1[0], tupledArg_1[1]);
    }, tryFind("to_guid", query)), bind((arg_2) => {
        let tupledArg_2;
        let outArg_2 = 0;
        tupledArg_2 = [tryParse_1(arg_2, 511, true, 32, new FSharpRef(() => outArg_2, (v_2) => {
            outArg_2 = v_2;
        })), outArg_2];
        return tryToOption(tupledArg_2[0], tupledArg_2[1]);
    }, tryFind("from_seed", query)), bind((arg_3) => {
        let tupledArg_3;
        let outArg_3 = 0;
        tupledArg_3 = [tryParse_1(arg_3, 511, true, 32, new FSharpRef(() => outArg_3, (v_3) => {
            outArg_3 = v_3;
        })), outArg_3];
        return tryToOption(tupledArg_3[0], tupledArg_3[1]);
    }, tryFind("to_seed", query)));
}

export function formatPathForVidValues(vidValues) {
    return RouterModule_encodeParts(singleton_1("/" + RouterModule_encodeQueryString(toList(delay(() => {
        if (vidValues == null) {
            return empty();
        }
        else {
            const toValue = vidValues[1];
            const fromValue = vidValues[0];
            return append((fromValue.tag === 1) ? singleton(["from_guid", fromValue.fields[0]]) : ((fromValue.tag === 2) ? singleton(["from_seed", fromValue.fields[0].toString()]) : singleton(["from_value", fromValue.fields[0]])), delay(() => ((toValue.tag === 1) ? singleton(["to_guid", toValue.fields[0]]) : ((toValue.tag === 2) ? singleton(["to_seed", toValue.fields[0].toString()]) : singleton(["to_value", toValue.fields[0]])))));
        }
    })))), 2);
}

export function canonicalUrl(state) {
    return canonicalBaseUrl + formatPathForVidValues(state.VidValues);
}

export function oEmbedUrl(url) {
    return oEmbedApiEndpoint + RouterModule_encodeParts(singleton_1("" + RouterModule_encodeQueryString(singleton_1(["url", url]))), 2);
}

export function pageTitle(vidValues) {
    if (vidValues == null) {
        return siteName + " face morpher";
    }
    else {
        const fromValue = vidValues[0];
        const arg20 = shortCheckfaceSrcDesc(vidValues[1]);
        const arg10 = shortCheckfaceSrcDesc(fromValue);
        return toText(printf("%s to %s"))(arg10)(arg20);
    }
}

export function pageDescription(_arg1) {
    if (_arg1 == null) {
        return "Generate faces on the fly and morph between them";
    }
    else {
        const fromValue = _arg1[0];
        const arg20 = shortCheckfaceSrcDesc(_arg1[1]);
        const arg10 = shortCheckfaceSrcDesc(fromValue);
        return toText(printf("Morph generated faces from %s to %s"))(arg10)(arg20);
    }
}

export function getCurrentPath(_arg1) {
    return [window.location.pathname, window.location.search];
}

export function parseSegments(pathName, queryString) {
    return [RouterModule_urlSegments(pathName, 2), ofSeq(map((arr) => [arr[0], arr[1]], (new URLSearchParams(queryString)).entries()))];
}

export function chooseCheckfaceSrc(value, guid, seed) {
    const matchValue = [value, guid, seed];
    if (matchValue[0] == null) {
        if (matchValue[1] == null) {
            if (matchValue[2] == null) {
                return void 0;
            }
            else {
                return new CheckfaceSrc(2, matchValue[2]);
            }
        }
        else {
            return new CheckfaceSrc(1, matchValue[1]);
        }
    }
    else {
        return new CheckfaceSrc(0, matchValue[0]);
    }
}

export function urlFromCheckfaceSrc(urlState) {
    return chooseCheckfaceSrc(urlState.FromValue, urlState.FromGuid, urlState.FromSeed);
}

export function urlToCheckfaceSrc(urlState) {
    return chooseCheckfaceSrc(urlState.ToValue, urlState.ToGuid, urlState.ToSeed);
}

export const defaultFromValue = (option) => defaultArg(option, new CheckfaceSrc(0, defaultTextValue));

export const defaultToValue = (option) => defaultArgWith(option, () => {
    let copyOfStruct;
    return new CheckfaceSrc(0, (copyOfStruct = today(), toString(copyOfStruct, "yyyy-MM-dd")));
});

export function initByUrlstate(urlState) {
    const fromValue = urlFromCheckfaceSrc(urlState);
    const toValue = urlToCheckfaceSrc(urlState);
    return new State(map2((a, b) => [a, b], fromValue, toValue), defaultFromValue(fromValue), defaultToValue(toValue), void 0, void 0, false, void 0, false, false);
}

export const initByUrl = (arg_1) => {
    let tupledArg_1, tupledArg;
    return initByUrlstate((tupledArg_1 = (tupledArg = arg_1, parseSegments(tupledArg[0], tupledArg[1])), parseUrl(tupledArg_1[0], tupledArg_1[1])));
};

export function init() {
    return [initByUrl(getCurrentPath()), Cmd_none()];
}

export function updateShare(msg, state) {
    let clipboard, matchValue, vidValues;
    switch (msg.tag) {
        case 1: {
            return [new State(state.VidValues, state.LeftValue, state.RightValue, state.UploadDialogSide, state.BrowseFacesDialogSide, false, state.ShareLinkMsg, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
        case 2: {
            gtag('event', "CopyLink", { 'event_category': "Share" });
            return [state, (clipboard_1 == null) ? Cmd_OfFunc_result(new Msg(8, new ShareMsg(3, "Failed to copy to clipboard 😢"))) : (clipboard = clipboard_1, Cmd_OfPromise_either((arg00) => clipboard.writeText(arg00), canonicalUrl(state), (_arg1) => (new Msg(8, new ShareMsg(3, "Copied link to clipboard!"))), (_arg2) => (new Msg(8, new ShareMsg(3, "Failed to copy to clipboard 😢")))))];
        }
        case 5: {
            gtag('event', "NavigatorShareOpen", { 'event_category': "Share" });
            return [state, (matchValue = [navigatorCanShare, state.VidValues], matchValue[0] ? ((matchValue[1] != null) ? (vidValues = matchValue[1], Cmd_OfPromise_result(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let fileShare;
                const FileName = pageTitle(state.VidValues) + ".mp4";
                const Title = pageTitle(state.VidValues);
                const FileUrl = vidSrc(videoDim, vidValues[0], vidValues[1]);
                const Text$ = pageDescription(state.VidValues);
                fileShare = (new FileShare(FileUrl, "video/mp4", FileName, Title, canonicalUrl(state), Text$));
                return shareFileFromUrl(fileShare).then(((_arg1_1) => (_arg1_1 ? (Promise.resolve((new Msg(8, new ShareMsg(3, "Sharing morph!"))))) : (Promise.resolve((new Msg(8, new ShareMsg(2))))))));
            })))) : Cmd_OfPromise_result(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                void navigatorShare(canonicalUrl(state), pageTitle(state.VidValues), pageDescription(state.VidValues));
                return Promise.resolve((new Msg(8, new ShareMsg(3, "Sharing page!"))));
            })))) : Cmd_OfFunc_result(new Msg(8, new ShareMsg(2))))];
        }
        case 3: {
            return [new State(state.VidValues, state.LeftValue, state.RightValue, state.UploadDialogSide, state.BrowseFacesDialogSide, state.ShareOpen, msg.fields[0], state.IsMorphLoading, state.UseSlider), Cmd_OfPromise_result(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, 1000))).then((() => (Promise.resolve((new Msg(8, new ShareMsg(4)))))))))))];
        }
        case 4: {
            return [new State(state.VidValues, state.LeftValue, state.RightValue, state.UploadDialogSide, state.BrowseFacesDialogSide, state.ShareOpen, void 0, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
        default: {
            return [new State(state.VidValues, state.LeftValue, state.RightValue, state.UploadDialogSide, state.BrowseFacesDialogSide, true, state.ShareLinkMsg, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
    }
}

export function update(msg, state) {
    let arg00;
    let pattern_matching_result;
    if (msg.tag === 0) {
        pattern_matching_result = 0;
    }
    else if (msg.tag === 1) {
        pattern_matching_result = 1;
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 2;
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 3;
    }
    else if (msg.tag === 5) {
        pattern_matching_result = 4;
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 5;
    }
    else if (msg.tag === 7) {
        if (equals(state.VidValues, [state.LeftValue, state.RightValue])) {
            pattern_matching_result = 6;
        }
        else {
            pattern_matching_result = 7;
        }
    }
    else {
        pattern_matching_result = 7;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new State(state.VidValues, msg.fields[0], state.RightValue, state.UploadDialogSide, state.BrowseFacesDialogSide, state.ShareOpen, state.ShareLinkMsg, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
        case 1: {
            return [new State(state.VidValues, state.LeftValue, msg.fields[0], state.UploadDialogSide, state.BrowseFacesDialogSide, state.ShareOpen, state.ShareLinkMsg, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
        case 2: {
            return [new State(state.VidValues, state.LeftValue, state.RightValue, msg.fields[0], state.BrowseFacesDialogSide, state.ShareOpen, state.ShareLinkMsg, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
        case 3: {
            return [new State(state.VidValues, state.LeftValue, state.RightValue, void 0, state.BrowseFacesDialogSide, state.ShareOpen, state.ShareLinkMsg, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
        case 4: {
            return [new State(state.VidValues, state.LeftValue, state.RightValue, state.UploadDialogSide, void 0, state.ShareOpen, state.ShareLinkMsg, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
        case 5: {
            return [new State(state.VidValues, state.LeftValue, state.RightValue, state.UploadDialogSide, msg.fields[0], state.ShareOpen, state.ShareLinkMsg, state.IsMorphLoading, state.UseSlider), Cmd_none()];
        }
        case 6: {
            return [state, Cmd_none()];
        }
        case 7: {
            switch (msg.tag) {
                case 7: {
                    gtag('event', "MakeVid", { 'event_category': "Video" });
                    return [state, (arg00 = formatPathForVidValues([state.LeftValue, state.RightValue]), singleton_1((_arg135) => {
                        RouterModule_nav(singleton_1(arg00), 1, 2);
                    }))];
                }
                case 6: {
                    const urlState = parseUrl(msg.fields[0][0], msg.fields[0][1]);
                    const urlFromSrc = urlFromCheckfaceSrc(urlState);
                    const urlToSrc = urlToCheckfaceSrc(urlState);
                    const vidValues_1 = map2((a, b) => [a, b], urlFromSrc, urlToSrc);
                    const isLoading = (!equals(vidValues_1, state.VidValues)) ? (vidValues_1 != null) : false;
                    return [new State(vidValues_1, defaultFromValue(urlFromSrc), defaultToValue(urlToSrc), state.UploadDialogSide, state.BrowseFacesDialogSide, false, void 0, isLoading, state.UseSlider), Cmd_none()];
                }
                case 8: {
                    return updateShare(msg.fields[0], state);
                }
                case 9: {
                    return [new State(state.VidValues, state.LeftValue, state.RightValue, state.UploadDialogSide, state.BrowseFacesDialogSide, state.ShareOpen, state.ShareLinkMsg, false, state.UseSlider), Cmd_none()];
                }
                case 10: {
                    return [new State(state.VidValues, state.LeftValue, state.RightValue, state.UploadDialogSide, state.BrowseFacesDialogSide, state.ShareOpen, state.ShareLinkMsg, state.IsMorphLoading, msg.fields[0]), Cmd_none()];
                }
                default: {
                    throw (new Error("Match failure: AppState.Msg"));
                }
            }
        }
    }
}

export function getShareState(state) {
    return new ShareState(canonicalUrl(state), state.VidValues != null, state.ShareLinkMsg, state.ShareOpen);
}

export const header = createElement("header", {
    style: {
        marginBottom: 2 + "em",
    },
    children: Interop_reactApi.Children.toArray([column(empty_1(), singleton_1(createElement("a", {
        href: "/",
        children: Interop_reactApi.Children.toArray([createElement("h1", {
            className: "title ",
            style: {
                fontSize: "clamp(2.5rem, 12vw, 4rem)",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 0.5 + "em",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(SvgIcon, [["className", "vivus-start"], ["component", animatedLogo], ["style", {
                fontSize: 1 + "em",
            }]]), createElement("span", {
                children: [siteName],
            })]),
        })]),
    })))]),
});

export const footer = footer_1(singleton_1(new Common_GenericOption(0, "app-footer")), singleton_1(MuiHelpers_createElement(Container, [["maxWidth", "md"], ["children", Interop_reactApi.Children.toArray([(() => {
    let children_5, children, children_3, children_7;
    const children_9 = ofArray([(children_5 = ofArray([(children = ofArray(["Contact: ", MuiHelpers_createElement(Link, [["color", "initial"], ["href", toText(printf("mailto:%s"))(contactEmail)], ["children", contactEmail]])]), createElement("p", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_3 = ofArray(["Source code: ", MuiHelpers_createElement(Link, [["color", "initial"], ["href", toText(printf("https://github.com/%s"))(githubRepo)], ["children", Interop_reactApi.Children.toArray([react.createElement(GitHub, {
        style: {
            fontSize: 1 + "em",
        },
    }), " " + githubRepo])]])]), createElement("p", {
        children: Interop_reactApi.Children.toArray(Array.from(children_3)),
    })), createElement("p", {
        children: ["If you find any bugs, please open an issue on GitHub"],
    })]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_5)),
    })), (children_7 = singleton_1(MuiHelpers_createElement(SvgIcon, [["component", logo], ["style", {
        fontSize: 5 + "rem",
    }]])), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
    }))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_9)),
    });
})()])]])));

export function createTheme(isDark) {
    return toList(delay(() => append(isDark ? singleton(["palette.type", "dark"]) : singleton(["palette.type", "light"]), delay(() => append(singleton(["palette.background.default", isDark ? "#17181c" : "white"]), delay(() => append(singleton(["palette.background.paper", isDark ? "#353535" : "#fff"]), delay(() => append(singleton(["palette.background.level2", isDark ? "#2a2a2a" : "#f5f5f5"]), delay(() => {
        const headingFamily = "\u0027Nunito\u0027, \u0027Segoe UI\u0027, sans-serif";
        return append(singleton(["typography.h1.fontFamily", headingFamily]), delay(() => append(singleton(["typography.h2.fontFamily", headingFamily]), delay(() => append(singleton(["typography.h3.fontFamily", headingFamily]), delay(() => append(singleton(["typography.h4.fontFamily", headingFamily]), delay(() => append(singleton(["typography.subtitle1.fontFamily", headingFamily]), delay(() => append(singleton(["typography.fontFamily", "\u0027Roboto\u0027, \u0027Segoe UI\u0027, sans-serif"]), delay(() => singleton(["typography.body2.fontSize", 1 + "rem"])))))))))))));
    }))))))))));
}

export const darkTheme = (() => {
    const merge = [];
    return StyleImports_createMuiTheme_argsArray(fromFlatEntries(createTheme(true)), ...merge);
})();

export const lightTheme = (() => {
    const merge = [];
    return StyleImports_createMuiTheme_argsArray(fromFlatEntries(createTheme(false)), ...merge);
})();

export function ThemedApp(themedAppInputProps) {
    const children = themedAppInputProps.children;
    return MuiHelpers_createElement(ThemeProvider, [["theme", HookImports_useMediaQuery("@media (prefers-color-scheme: dark)") ? darkTheme : lightTheme], ["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function render(state, dispatch) {
    return createElement(ThemedApp, {
        children: [MuiHelpers_createElement(CssBaseline, []), header, renderContent(state, dispatch), renderEncodeImageDialog(state, dispatch), renderBrowseFacesDialog(state, dispatch), viewShareContent(getShareState(state), (arg) => {
            dispatch(new Msg(8, arg));
        }), view_1(), footer],
    });
}

export function meta(property, content) {
    return createElement("meta", {
        property: property,
        content: content,
    });
}

export function viewHead(state) {
    const canonicalUrl_1 = canonicalUrl(state);
    const title = pageTitle(state.VidValues);
    return MuiHelpers_createElement(Helmet, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("title", {
        children: [title],
    })), delay(() => append(singleton(createElement("meta", {
        property: "description",
        name: "description",
        content: pageDescription(void 0),
    })), delay(() => append(singleton(meta("og:title", title)), delay(() => append(singleton(meta("og:description", pageDescription(void 0))), delay(() => append(singleton(meta("og:site_name", siteName)), delay(() => append(singleton(createElement("link", {
        rel: "canonical",
        href: canonicalUrl_1,
    })), delay(() => append(singleton(meta("og:url", canonicalUrl_1)), delay(() => append(singleton(createElement("link", {
        rel: "alternate",
        type: "application/json+oembed",
        href: oEmbedUrl(canonicalUrl_1),
        title: toText(interpolate("oEmbed for %s%P()", [title])),
    })), delay(() => {
        const matchValue = state.VidValues;
        if (matchValue != null) {
            const vidValues = matchValue;
            const videoSrc = vidSrc(ogVideoDim, vidValues[0], vidValues[1]);
            const linkprevSrc = linkpreviewSrc(linkpreviewWidth, vidValues[0], vidValues[1]);
            const linkprevAlt = linkpreviewAlt(vidValues[0], vidValues[1]);
            return append(singleton(meta("og:image", linkprevSrc)), delay(() => append(singleton(meta("og:image:alt", linkprevAlt)), delay(() => append(singleton(meta("og:image:width", linkpreviewWidth)), delay(() => append(singleton(meta("og:image:height", linkpreviewHeight)), delay(() => append(singleton(meta("og:image:type", "image/jpeg")), delay(() => append(singleton(meta("og:type", "video.other")), delay(() => append(singleton(meta("og:video", videoSrc)), delay(() => append(singleton(meta("og:video:secure_url", videoSrc)), delay(() => append(singleton(meta("og:video:type", "video/mp4")), delay(() => append(singleton(meta("og:video:width", ogVideoDim)), delay(() => append(singleton(meta("og:video:height", ogVideoDim)), delay(() => append(singleton(meta("twitter:card", "player")), delay(() => append(singleton(meta("twitter:player", videoSrc + "\u0026embed_html=true")), delay(() => append(singleton(meta("twitter:player:width", ogVideoDim)), delay(() => singleton(meta("twitter:player:height", ogVideoDim))))))))))))))))))))))))))))));
        }
        else {
            return append(singleton(meta("og:image", imgSrc(ogImgDim, false, state.LeftValue))), delay(() => append(singleton(meta("og:image:alt", imgAlt(state.LeftValue))), delay(() => append(singleton(meta("og:image:width", ogImgDim)), delay(() => append(singleton(meta("og:image:height", ogImgDim)), delay(() => append(singleton(meta("og:image:type", "image/jpeg")), delay(() => singleton(meta("og:type", "website"))))))))))));
        }
    }))))))))))))))))))))]]);
}

export function RemoveServerSideJss() {
    useReact_useEffectOnce_3A5B6456(() => {
        const jssStyles = document.getElementById("jss-server-side");
        if ((!!jssStyles)) {
            void jssStyles.parentElement.removeChild(jssStyles);
        }
    });
    return react.createElement(react.Fragment, {});
}

export function viewWithoutRouter(state, dispatch) {
    return react.createElement(react.Fragment, {}, viewHead(state), createElement(RemoveServerSideJss, null), render(state, dispatch));
}

export function view(state, dispatch) {
    return RouterModule_router({
        hashMode: 2,
        onUrlChanged: (arg_2) => {
            let tupledArg;
            dispatch(new Msg(6, (tupledArg = getCurrentPath(arg_2), parseSegments(tupledArg[0], tupledArg[1]))));
        },
        application: viewWithoutRouter(state, dispatch),
    });
}

