import { Record } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { CheckfaceSrc$reflection } from "./Checkface.fs.js";
import { record_type, string_type, int32_type, lambda_type, unit_type, tuple_type } from "../Server/.fable/fable-library.3.1.12/Reflection.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "../Server/.fable/Feliz.1.40.1/React.fs.js";
import { map, length, item } from "../Server/.fable/fable-library.3.1.12/List.js";
import { comparePrimitives, min } from "../Server/.fable/fable-library.3.1.12/Util.js";
import { some } from "../Server/.fable/fable-library.3.1.12/Option.js";
import { toList } from "../Server/.fable/fable-library.3.1.12/Seq.js";
import { rangeDouble } from "../Server/.fable/fable-library.3.1.12/Range.js";
import { Helpers_equalsButFunctions } from "../Server/.fable/Fable.React.7.0.1/Fable.React.Helpers.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../Server/.fable/Feliz.1.40.1/Interop.fs.js";
import { MuiHelpers_createElement } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Slider from "@material-ui/core/Slider";

export class Props extends Record {
    constructor(Values, OnLoaded, FrameSrc, Dim, NumFrames) {
        super();
        this.Values = Values;
        this.OnLoaded = OnLoaded;
        this.FrameSrc = FrameSrc;
        this.Dim = (Dim | 0);
        this.NumFrames = (NumFrames | 0);
    }
}

export function Props$reflection() {
    return record_type("SliderMorph.Props", [], Props, () => [["Values", tuple_type(CheckfaceSrc$reflection(), CheckfaceSrc$reflection())], ["OnLoaded", lambda_type(unit_type, unit_type)], ["FrameSrc", lambda_type(tuple_type(CheckfaceSrc$reflection(), CheckfaceSrc$reflection()), lambda_type(int32_type, lambda_type(int32_type, lambda_type(int32_type, string_type))))], ["Dim", int32_type], ["NumFrames", int32_type]]);
}

export function ViewSliderMorph(props) {
    let frames_1;
    const canvasRef = useReact_useRef_1505(void 0);
    const frames = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(1 + (~(~(props.NumFrames / 2))));
    const frameNum = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const matchValue = frames.current;
    let pattern_matching_result, frames_2, lastProps_1;
    if (matchValue != null) {
        if (frames_1 = matchValue[0], Helpers_equalsButFunctions(matchValue[1], props)) {
            pattern_matching_result = 0;
            frames_2 = matchValue[0];
            lastProps_1 = matchValue[1];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const matchValue_1 = canvasRef.current;
            if (matchValue_1 != null) {
                const canvas_1 = matchValue_1;
                canvas_1.height = props.Dim;
                canvas_1.width = props.Dim;
                const context = canvas_1.getContext('2d');
                const currentImage = item(min((x, y) => comparePrimitives(x, y), frameNum, length(frames_2)) - 1, frames_2);
                if (currentImage.complete) {
                    context.drawImage(currentImage, 0, 0);
                }
            }
            else {
                console.log(some("Did nothing :("));
            }
            break;
        }
        case 1: {
            console.log(some("Making image frames"));
            frames.current = [map((n) => {
                const img = new Image(props.Dim, props.Dim);
                if (n === frameNum) {
                    img.onload = ((arg) => {
                        props.OnLoaded(void arg);
                    });
                }
                else {
                    img.onload = ((_arg1) => {
                        patternInput_1[1](n);
                    });
                }
                img.src = props.FrameSrc(props.Values, props.Dim, props.NumFrames, n - 1);
                return img;
            }, toList(rangeDouble(1, 1, props.NumFrames))), props];
            break;
        }
    }
    return createElement("div", {
        style: {
            display: "block",
            margin: "auto",
        },
        children: Interop_reactApi.Children.toArray([createElement("canvas", {
            ref: canvasRef,
            style: {
                maxWidth: 100 + "%",
                height: "auto",
                display: "block",
                margin: "auto",
            },
            width: props.Dim,
            height: props.Dim,
        }), MuiHelpers_createElement(Slider, [["style", {
            display: "block",
            maxWidth: props.Dim + "px",
            margin: "auto",
        }], ["min", 1], ["max", props.NumFrames], ["onChange", (_arg25, v) => {
            patternInput[1](v);
        }], ["value", frameNum]])]),
    });
}

