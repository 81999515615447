import { ofArray } from "../Server/.fable/fable-library.3.1.12/List.js";

export const videoDim = 512;

export const imgDim = 300;

export const imgSizesSet = ofArray([300, 512, 1024]);

export const linkpreviewWidth = 1200;

export const linkpreviewHeight = 628;

export const ogImgDim = 512;

export const ogVideoDim = 512;

export const maxSupportedImgDim = 1024;

export const thumbnailDim = 128;

export const siteName = "facemorph.me";

export const canonicalBaseUrl = "https://facemorph.me";

export const oEmbedApiEndpoint = canonicalBaseUrl + "/oembed.json";

export const contactEmail = "checkfaceml@gmail.com";

export const githubRepo = "check-face/facemorph.me";

export const defaultTextValue = "hello";

export const defaultNumericSeed = 389;

export const apiAddr = "https://api.facemorph.me";

export const encodeApiAddr = apiAddr + "/api/encodeimage/";

export const browseFacesEmbedSrc = "https://names.facemorph.me";

