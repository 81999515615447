import explain from "./explain.md";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty, tail as tail_1, isEmpty, tryHead, unfold, singleton } from "../Server/.fable/fable-library.3.1.12/List.js";
import { trim, substring } from "../Server/.fable/fable-library.3.1.12/String.js";
import { defaultArg } from "../Server/.fable/fable-library.3.1.12/Option.js";
import { empty as empty_1, append, singleton as singleton_1, collect, delay, toList } from "../Server/.fable/fable-library.3.1.12/Seq.js";
import { MuiHelpers_createElement } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Container from "@material-ui/core/Container";
import { Interop_reactApi } from "../Server/.fable/Feliz.1.40.1/Interop.fs.js";
import { Option, content as content_4 } from "../Server/.fable/Fulma.2.9.0/Elements/Content.fs.js";
import { Size_ISize } from "../Server/.fable/Fulma.2.9.0/Common.fs.js";
import { createElement } from "react";
import * as react from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";

export const explainContent = explain;

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    o = {
        accordionSummary: StyleCreator$1__create_4EAC9E1E(styles, singleton(["backgroundColor", arg.palette.background.level2])),
        content: StyleCreator$1__create_4EAC9E1E(styles, singleton(["maxWidth", 100 + "%"])),
    };
    return Object.assign({}, o);
});

export function Explain() {
    let children_2;
    const c = useStyles();
    const parts = unfold((content) => {
        const matchValue = [content, content.indexOf("\u003chr\u003e")];
        if (matchValue[0] === "") {
            return void 0;
        }
        else if (matchValue[1] === -1) {
            return [content, ""];
        }
        else {
            const idx = matchValue[1] | 0;
            return [substring(content, 0, idx), substring(content, idx + "\u003chr\u003e".length)];
        }
    }, explainContent);
    const topContent = defaultArg(tryHead(parts), "");
    const faqSections = (!isEmpty(parts)) ? tail_1(parts) : empty();
    const faq = toList(delay(() => collect((part) => {
        const part_1 = trim(part, " ", "\n");
        const matchValue_1 = [part_1, part_1.lastIndexOf("\u003c/h")];
        if (matchValue_1[1] === -1) {
            return singleton_1([part_1, void 0]);
        }
        else if (matchValue_1[1] === 0) {
            return singleton_1([part_1, void 0]);
        }
        else {
            const idx_1 = matchValue_1[1] | 0;
            return singleton_1([substring(part_1, 0, idx_1 + 5), substring(part_1, idx_1 + 5)]);
        }
    }, faqSections)));
    return MuiHelpers_createElement(Container, [["maxWidth", "md"], ["style", {
        marginBottom: 2 + "em",
    }], ["children", Interop_reactApi.Children.toArray([content_4(singleton(new Option(0, new Size_ISize(2))), singleton(createElement("div", {
        dangerouslySetInnerHTML: {
            __html: topContent,
        },
    }))), (children_2 = toList(delay(() => collect((matchValue_2) => {
        const answer = matchValue_2[1];
        return singleton_1(MuiHelpers_createElement(Accordion, [["square", true], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(AccordionSummary, [["expandIcon", react.createElement(ExpandMore, {})], ["classes.root", c.accordionSummary], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["component", "h3"], ["variant", "h6"], ["dangerouslySetInnerHTML", {
            __html: matchValue_2[0],
        }]])])]])), delay(() => {
            if (answer != null) {
                const answer_1 = answer;
                return singleton_1(MuiHelpers_createElement(AccordionDetails, [["children", Interop_reactApi.Children.toArray([content_4(singleton(new Option(1, c.content)), singleton(createElement("div", {
                    dangerouslySetInnerHTML: {
                        __html: answer_1,
                    },
                })))])]]));
            }
            else {
                return empty_1();
            }
        }))))))]]));
    }, faq))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))])]]);
}

export function view() {
    return createElement(Explain, null);
}

