import { Record, Union } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { list_type, uint32_type, class_type, record_type, string_type, bool_type, option_type, tuple_type, union_type } from "../Server/.fable/fable-library.3.1.12/Reflection.js";
import { CheckfaceSrc$reflection } from "./Checkface.fs.js";
import { ShareMsg$reflection } from "./Share.fs.js";

export class Side extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Left", "Right"];
    }
}

export function Side$reflection() {
    return union_type("AppState.Side", [], Side, () => [[], []]);
}

export class State extends Record {
    constructor(VidValues, LeftValue, RightValue, UploadDialogSide, BrowseFacesDialogSide, ShareOpen, ShareLinkMsg, IsMorphLoading, UseSlider) {
        super();
        this.VidValues = VidValues;
        this.LeftValue = LeftValue;
        this.RightValue = RightValue;
        this.UploadDialogSide = UploadDialogSide;
        this.BrowseFacesDialogSide = BrowseFacesDialogSide;
        this.ShareOpen = ShareOpen;
        this.ShareLinkMsg = ShareLinkMsg;
        this.IsMorphLoading = IsMorphLoading;
        this.UseSlider = UseSlider;
    }
}

export function State$reflection() {
    return record_type("AppState.State", [], State, () => [["VidValues", option_type(tuple_type(CheckfaceSrc$reflection(), CheckfaceSrc$reflection()))], ["LeftValue", CheckfaceSrc$reflection()], ["RightValue", CheckfaceSrc$reflection()], ["UploadDialogSide", option_type(Side$reflection())], ["BrowseFacesDialogSide", option_type(Side$reflection())], ["ShareOpen", bool_type], ["ShareLinkMsg", option_type(string_type)], ["IsMorphLoading", bool_type], ["UseSlider", bool_type]]);
}

export class UrlState extends Record {
    constructor(FromValue, ToValue, FromGuid, ToGuid, FromSeed, ToSeed) {
        super();
        this.FromValue = FromValue;
        this.ToValue = ToValue;
        this.FromGuid = FromGuid;
        this.ToGuid = ToGuid;
        this.FromSeed = FromSeed;
        this.ToSeed = ToSeed;
    }
}

export function UrlState$reflection() {
    return record_type("AppState.UrlState", [], UrlState, () => [["FromValue", option_type(string_type)], ["ToValue", option_type(string_type)], ["FromGuid", option_type(class_type("System.Guid"))], ["ToGuid", option_type(class_type("System.Guid"))], ["FromSeed", option_type(uint32_type)], ["ToSeed", option_type(uint32_type)]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetLeftValue", "SetRightValue", "ClickUploadRealImage", "BrowseCheckfaceValues", "CloseUploadDialog", "CloseBrowseFacesDialog", "UrlChanged", "MakeVid", "ShareMsg", "MorphLoaded", "SetUseSlider"];
    }
}

export function Msg$reflection() {
    return union_type("AppState.Msg", [], Msg, () => [[["Item", CheckfaceSrc$reflection()]], [["Item", CheckfaceSrc$reflection()]], [["Item", Side$reflection()]], [["Item", Side$reflection()]], [], [], [["Item", tuple_type(list_type(string_type), class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))]], [], [["Item", ShareMsg$reflection()]], [], [["Item", bool_type]]]);
}

