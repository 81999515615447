import { Union } from "../../fable-library.3.1.12/Types.js";
import { list_type, class_type, string_type, union_type } from "../../fable-library.3.1.12/Reflection.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Reflection_getCaseName, Modifier_IModifier$reflection, Screen$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.1.12/MapUtil.js";

export class ISize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-one-quarter", "is-one-third", "is-half", "is-two-thirds", "is-three-quarters", "is-1", "is-2", "is-3", "is-4", "is-5", "is-6", "is-7", "is-8", "is-9", "is-10", "is-11", "is-12", "is-narrow", "is-full", "is-one-fifth", "is-two-fifths", "is-three-fifths", "is-four-fifths"];
    }
}

export function ISize$reflection() {
    return union_type("Fulma.Column.ISize", [], ISize, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Width", "Offset", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Column.Option", [], Option, () => [[["Item1", Screen$reflection()], ["Item2", ISize$reflection()]], [["Item1", Screen$reflection()], ["Item2", ISize$reflection()]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

function suffix(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "-desktop";
        }
        case 2: {
            return "-tablet";
        }
        case 3: {
            return "-mobile";
        }
        case 4: {
            return "-widescreen";
        }
        case 6: {
            return "-fullhd";
        }
        case 5: {
            return "-touch";
        }
        default: {
            return "";
        }
    }
}

export function ofWidth(screen, size) {
    return Reflection_getCaseName(size) + suffix(screen);
}

export function ofOffset(screen, offset) {
    const className = Reflection_getCaseName(offset);
    return ("is-offset-" + className.slice(3, className.length)) + suffix(screen);
}

export function column(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, ofOffset(option.fields[0], option.fields[1]));
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 4: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, ofWidth(option.fields[0], option.fields[1]));
            }
        }
    }, "column"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

