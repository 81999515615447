import { FSharpRef, Union } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { union_type, uint32_type, class_type, string_type } from "../Server/.fable/fable-library.3.1.12/Reflection.js";
import { apiAddr, defaultTextValue, defaultNumericSeed } from "./Config.fs.js";
import { tryParse } from "../Server/.fable/fable-library.3.1.12/Int32.js";
import { substring, printf, toText } from "../Server/.fable/fable-library.3.1.12/String.js";

export class CheckfaceSrc extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CheckfaceValue", "Guid", "Seed"];
    }
}

export function CheckfaceSrc$reflection() {
    return union_type("Checkface.CheckfaceSrc", [], CheckfaceSrc, () => [[["Item", string_type]], [["Item", class_type("System.Guid")]], [["Item", uint32_type]]]);
}

export function $007CAsNumericSeed$007C(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return defaultNumericSeed;
        }
        case 0: {
            let matchValue;
            let outArg = 0;
            matchValue = [tryParse(_arg1.fields[0], 511, true, 32, new FSharpRef(() => outArg, (v) => {
                outArg = v;
            })), outArg];
            if (matchValue[0]) {
                return matchValue[1];
            }
            else {
                return defaultNumericSeed;
            }
        }
        default: {
            return _arg1.fields[0];
        }
    }
}

export function $007CAsTextValue$007C(_arg1) {
    switch (_arg1.tag) {
        case 0: {
            return _arg1.fields[0];
        }
        case 1: {
            return defaultTextValue;
        }
        default: {
            return _arg1.fields[0].toString();
        }
    }
}

export function shortCheckfaceSrcDesc(_arg1) {
    switch (_arg1.tag) {
        case 2: {
            return toText(printf("seed %i"))(_arg1.fields[0]);
        }
        case 1: {
            const arg10_1 = substring(_arg1.fields[0], 0, 6);
            return toText(printf("custom (%s)"))(arg10_1);
        }
        default: {
            return _arg1.fields[0];
        }
    }
}

export function valueParam(value) {
    switch (value.tag) {
        case 0: {
            const arg10_1 = encodeURIComponent(value.fields[0]);
            return toText(printf("value=%s"))(arg10_1);
        }
        case 2: {
            return toText(printf("seed=%i"))(value.fields[0]);
        }
        default: {
            return toText(printf("guid=%s"))(value.fields[0]);
        }
    }
}

export function imgSrc(dim, isWebp, value) {
    const arg40 = isWebp ? "webp" : "jpg";
    const arg30 = valueParam(value);
    return toText(printf("%s/api/face/?dim=%i\u0026%s\u0026format=%s"))(apiAddr)(dim)(arg30)(arg40);
}

export function vidSrc(dim, fromValue, toValue) {
    const arg40 = valueParam(toValue);
    const arg30 = valueParam(fromValue);
    return toText(printf("%s/api/mp4/?dim=%i\u0026from_%s\u0026to_%s"))(apiAddr)(dim)(arg30)(arg40);
}

export function animatedWebpSrc(dim, fromValue, toValue) {
    const arg40 = valueParam(toValue);
    const arg30 = valueParam(fromValue);
    return toText(printf("%s/api/webp/?dim=%i\u0026from_%s\u0026to_%s"))(apiAddr)(dim)(arg30)(arg40);
}

export function morphframeSrc(fromValue, toValue, dim, numFrames, frameNum) {
    const arg40 = valueParam(toValue);
    const arg30 = valueParam(fromValue);
    return toText(printf("%s/api/morphframe/?dim=%i\u0026linear=true\u0026from_%s\u0026to_%s\u0026num_frames=%i\u0026frame_num=%i"))(apiAddr)(dim)(arg30)(arg40)(numFrames)(frameNum);
}

export function linkpreviewSrc(width, fromValue, toValue) {
    const arg40 = valueParam(toValue);
    const arg30 = valueParam(fromValue);
    return toText(printf("%s/api/linkpreview/?width=%i\u0026from_%s\u0026to_%s"))(apiAddr)(width)(arg30)(arg40);
}

export function describeCheckfaceSrc(_arg1) {
    switch (_arg1.tag) {
        case 2: {
            return toText(printf("seed %i"))(_arg1.fields[0]);
        }
        case 1: {
            const arg10_2 = substring(_arg1.fields[0], 0, 6);
            return toText(printf("custom latent (%s)"))(arg10_2);
        }
        default: {
            return toText(printf("value %s"))(_arg1.fields[0]);
        }
    }
}

export function imgAlt(value) {
    return "Generated face for " + describeCheckfaceSrc(value);
}

export function vidMorphAlt(fromValue, toValue) {
    const arg20 = describeCheckfaceSrc(toValue);
    const arg10 = describeCheckfaceSrc(fromValue);
    return toText(printf("Morph from %s to %s"))(arg10)(arg20);
}

export function linkpreviewAlt(fromValue, toValue) {
    const arg20 = describeCheckfaceSrc(toValue);
    const arg10 = describeCheckfaceSrc(fromValue);
    return toText(printf("Preview of %s + %s"))(arg10)(arg20);
}

