import { StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { MuiHelpers_createElement } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import { singleton, append, delay, toList } from "../Server/.fable/fable-library.3.1.12/Seq.js";

const theStyles = {
    root: {
        color: "rgba(255,255,255,0.9)",
        background: "linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5)",
        backgroundSize: "800%",
        animation: "$anim 10s linear infinite",
        backgroundPosition: "50% 100%",
        transition: "opacity 0.4s ease-out",
    },
    ["@keyframes anim"]: {
        ["0%"]: {
            backgroundPosition: "0% 50%",
        },
        ["50%"]: {
            backgroundPosition: "100% 50%",
        },
        ["100%"]: {
            backgroundPosition: "0% 50%",
        },
    },
};

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    o = theStyles;
    return Object.assign({}, o);
});

export function FancyButton(fancyButtonInputProps) {
    const buttonProps = fancyButtonInputProps.buttonProps;
    const classes = useStyles();
    return MuiHelpers_createElement(Button, toList(delay(() => append(singleton(["classes.root", classes.root]), delay(() => buttonProps)))));
}

