import { tail, head, isEmpty, cons } from "../Server/.fable/fable-library.3.1.12/List.js";
import { some } from "../Server/.fable/fable-library.3.1.12/Option.js";
import { printf, toConsole } from "../Server/.fable/fable-library.3.1.12/String.js";
import { Record } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { record_type, string_type } from "../Server/.fable/fable-library.3.1.12/Reflection.js";
import { shareFile } from "./shareFile.js";

export function insert(value, index, list) {
    const matchValue = [index, list];
    if (matchValue[0] === 0) {
        return cons(value, matchValue[1]);
    }
    else if (isEmpty(matchValue[1])) {
        throw (new Error("index out of range"));
    }
    else {
        return cons(head(matchValue[1]), insert(value, matchValue[0] - 1, tail(matchValue[1])));
    }
}

export function remove(i, l) {
    const matchValue = [i, l];
    let pattern_matching_result;
    if (matchValue[0] === 0) {
        if (isEmpty(matchValue[1])) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else if (isEmpty(matchValue[1])) {
        pattern_matching_result = 2;
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return tail(matchValue[1]);
        }
        case 1: {
            return cons(head(matchValue[1]), remove(matchValue[0] - 1, tail(matchValue[1])));
        }
        case 2: {
            throw (new Error("index out of range"));
        }
    }
}

export function tryToOption(didSucceed, value) {
    if (didSucceed) {
        return some(value);
    }
    else {
        return void 0;
    }
}

export const clipboard = (typeof navigator === 'undefined') ? (void 0) : navigator.clipboard;

export const navigatorCanShare = typeof navigator !== 'undefined' && navigator.canShare && navigator.canShare({
    text: "examplewwe text",
    title: "nonononono",
    url: "https://example.com",
});

toConsole(printf("Can share? %A"))(navigatorCanShare);

export function navigatorShare(url, title, text) {
    return navigator.share({
        text: text,
        title: title,
        url: url,
    });
}

export function eventTargetElementValue(eventTarget) {
    return eventTarget.value;
}

export class FileShare extends Record {
    constructor(FileUrl, ContentType, FileName, Title, Url, Text$) {
        super();
        this.FileUrl = FileUrl;
        this.ContentType = ContentType;
        this.FileName = FileName;
        this.Title = Title;
        this.Url = Url;
        this.Text = Text$;
    }
}

export function FileShare$reflection() {
    return record_type("Utils.FileShare", [], FileShare, () => [["FileUrl", string_type], ["ContentType", string_type], ["FileName", string_type], ["Title", string_type], ["Url", string_type], ["Text", string_type]]);
}

export const shareFileFromUrl = shareFile;

