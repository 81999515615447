import { Union } from "../../fable-library.3.1.12/Types.js";
import { Common_GenericOptions__AddCaseName_1505, Reflection_getCaseName, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Size_ISize$reflection } from "../Common.fs.js";
import { union_type, list_type, class_type, string_type } from "../../fable-library.3.1.12/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.1.12/MapUtil.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Content.Option", [], Option, () => [[["Item", Size_ISize$reflection()]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function content(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 2: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            }
        }
    }, "content"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

export class Ol_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-lower-roman", "is-upper-roman", "is-lower-alpha", "is-upper-alpha", "CustomClass", "Modifiers", "Props"];
    }
}

export function Ol_Option$reflection() {
    return union_type("Fulma.Content.Ol.Option", [], Ol_Option, () => [[], [], [], [], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]]]);
}

export function Ol_ol(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 2:
            case 3: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 6: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 4: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 5: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }), (props_1, children_1) => react.createElement("ol", keyValueList(props_1, 1), ...children_1), children);
}

