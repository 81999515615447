import { Union, Record } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { union_type, record_type, string_type, class_type, bool_type, lambda_type, unit_type } from "../Server/.fable/fable-library.3.1.12/Reflection.js";
import { MuiHelpers_createElement } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Icon from "@material-ui/core/Icon";
import { createElement } from "react";
import * as react from "react";
import Error$ from "@material-ui/icons/Error";
import Grid from "@material-ui/core/Grid";
import { Interop_reactApi } from "../Server/.fable/Feliz.1.40.1/Interop.fs.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "../Server/.fable/Feliz.1.40.1/React.fs.js";
import { isNullOrEmpty, printf, toConsole } from "../Server/.fable/fable-library.3.1.12/String.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import SvgIcon from "@material-ui/core/SvgIcon";
import { logo } from "./Logos.fs.js";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { siteName } from "./Config.fs.js";
import { Option, column } from "../Server/.fable/Fulma.2.9.0/Layouts/Column.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../Server/.fable/fable-library.3.1.12/List.js";
import { empty as empty_1, singleton, append, delay, toList } from "../Server/.fable/fable-library.3.1.12/Seq.js";
import { Option as Option_1, columns } from "../Server/.fable/Fulma.2.9.0/Layouts/Columns.fs.js";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../Server/.fable/Fable.React.7.0.1/Fable.React.Extensions.fs.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { equals } from "../Server/.fable/fable-library.3.1.12/Util.js";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../Server/.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../Server/.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Types_RequestProperties, fetch$ } from "../Server/.fable/Fable.Fetch.2.2.0/Fetch.fs.js";
import { parse } from "../Server/.fable/fable-library.3.1.12/Guid.js";
import { some } from "../Server/.fable/fable-library.3.1.12/Option.js";
import { Modifier_IModifier, TextAlignment_Option, Screen } from "../Server/.fable/Fulma.2.9.0/Common.fs.js";
import Typography from "@material-ui/core/Typography";

export class EncodeImageDialogProps extends Record {
    constructor(OnClose, IsOpen, RenderImgGuid, EncodeImageApiLocation, OnImageEncoded) {
        super();
        this.OnClose = OnClose;
        this.IsOpen = IsOpen;
        this.RenderImgGuid = RenderImgGuid;
        this.EncodeImageApiLocation = EncodeImageApiLocation;
        this.OnImageEncoded = OnImageEncoded;
    }
}

export function EncodeImageDialogProps$reflection() {
    return record_type("EncodeImageDialog.EncodeImageDialogProps", [], EncodeImageDialogProps, () => [["OnClose", lambda_type(unit_type, unit_type)], ["IsOpen", bool_type], ["RenderImgGuid", lambda_type(class_type("System.Guid"), class_type("Fable.React.ReactElement"))], ["EncodeImageApiLocation", string_type], ["OnImageEncoded", lambda_type(class_type("System.Guid"), unit_type)]]);
}

export class Loadable$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotLoading", "Loading", "Loaded", "LoadingError"];
    }
}

export function Loadable$1$reflection(gen0) {
    return union_type("EncodeImageDialog.Loadable`1", [gen0], Loadable$1, () => [[], [], [["Item", gen0]], []]);
}

export function errorIcon() {
    return MuiHelpers_createElement(Icon, [["children", react.createElement(Error$, {})], ["color", "error"]]);
}

export function centerInGrid(elements) {
    return MuiHelpers_createElement(Grid, [["alignItems", "center"], ["justify", "center"], ["container", true], ["style", {
        height: 100 + "%",
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Grid, [["item", true], ["children", Interop_reactApi.Children.toArray(Array.from(elements))]])])]]);
}

export function isInvalidImageDataUrl(dataUrl) {
    if (!(dataUrl.indexOf("data:image") === 0)) {
        return true;
    }
    else {
        return dataUrl.indexOf("data:image/svg") === 0;
    }
}

export function EncodeImageDialog(props) {
    const fileInput = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(new Loadable$1(0));
    const setChosenFileDataUrl = patternInput[1];
    const chosenFileDataUrl = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Loadable$1(0));
    const setEncodeResult = patternInput_1[1];
    const encodedImageResult = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const setPasteHereValue = patternInput_2[1];
    const pasteHereValue = patternInput_2[0];
    const onFileInputChange = (e_2) => {
        const matchValue = fileInput.current;
        if (matchValue == null) {
        }
        else {
            const input = matchValue;
            setEncodeResult(new Loadable$1(0));
            if (input.files.length < 1) {
                setChosenFileDataUrl(new Loadable$1(0));
            }
            else {
                setChosenFileDataUrl(new Loadable$1(1));
                const file = input.files[0];
                const reader = new FileReader();
                reader.onload = ((e) => {
                    setChosenFileDataUrl(new Loadable$1(2, e.target.result));
                });
                reader.onerror = ((e_1) => {
                    setChosenFileDataUrl(new Loadable$1(3));
                });
                toConsole(printf("readAsDataUrl"));
                reader.readAsDataURL(file);
            }
        }
    };
    const onPasteEvent = (e_3) => {
        e_3.preventDefault();
        const matchValue_1 = fileInput.current;
        if (matchValue_1 == null) {
        }
        else {
            const input_2 = matchValue_1;
            e_3.preventDefault();
            if (e_3.clipboardData.files.length > 0) {
                input_2.files = e_3.clipboardData.files;
                onFileInputChange(null);
            }
            else {
                const asText = e_3.clipboardData.getData("text/plain");
                if (!isNullOrEmpty(asText)) {
                    setPasteHereValue(asText);
                }
            }
        }
    };
    const resetState = () => {
        setEncodeResult(new Loadable$1(0));
        setChosenFileDataUrl(new Loadable$1(0));
    };
    const close = (_arg5) => {
        resetState();
        props.OnClose();
    };
    return MuiHelpers_createElement(Dialog, [["open", props.IsOpen], ["onClose", (delegateArg0, delegateArg1) => {
        close();
    }], ["onPaste", onPasteEvent], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
            justifyContent: "space-between",
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            children: ["Upload an image"],
        }), MuiHelpers_createElement(SvgIcon, [["className", "vivus-start"], ["component", logo], ["style", {
            fontSize: 1.5 + "em",
        }]])]),
    })])]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogContentText, [["children", Interop_reactApi.Children.toArray([createElement("span", {
        children: ["You can upload an image of a real face to "],
    }), createElement("b", {
        children: [siteName],
    }), createElement("span", {
        children: [". We will attempt to find a value which approximates that face."],
    }), createElement("p", {
        children: ["Either choose an image file or paste an image from the clipboard."],
    })])]]), column(empty(), toList(delay(() => append(singleton(columns(empty(), singleton_1(MuiHelpers_createElement(Input, [["type", "file"], ["inputProps", {
        accept: "image/jpeg, image/png, image/webp",
    }], ["fullWidth", true], ["required", true], ["inputRef", fileInput], ["onChange", onFileInputChange]])))), delay(() => append(singleton(columns(empty(), singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["margin", "normal"]), delay(() => append(singleton(["autoFocus", true]), delay(() => append(singleton(["value", pasteHereValue]), delay(() => append(singleton(["placeholder", "Or paste image here"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["onPaste", onPasteEvent]), delay(() => append(singleton(["onChange", (e_5) => {
        setPasteHereValue(Browser_Types_Event__Event_get_Value(e_5));
    }]), delay(() => ((!isNullOrEmpty(pasteHereValue)) ? append(singleton(["error", true]), delay(() => singleton(["helperText", "Paste an actual image, not text."]))) : empty_1()))))))))))))))))))))), delay(() => {
        let children_2;
        return append((chosenFileDataUrl.tag === 1) ? singleton(columns(empty(), singleton_1(column(empty(), singleton_1(MuiHelpers_createElement(CircularProgress, [])))))) : ((chosenFileDataUrl.tag === 3) ? singleton(columns(empty(), singleton_1(column(empty(), ofArray([errorIcon(), createElement("p", {
            children: ["Something went wrong loading file. Try picking another image"],
        })]))))) : ((chosenFileDataUrl.tag === 2) ? (isInvalidImageDataUrl(chosenFileDataUrl.fields[0]) ? singleton(columns(empty(), singleton_1(column(empty(), ofArray([errorIcon(), createElement("p", {
            children: ["Not a valid image"],
        })]))))) : ((chosenFileDataUrl.tag === 2) ? singleton(columns(empty(), ofArray([column(empty(), singleton_1((children_2 = singleton_1(createElement("img", {
            style: {
                maxWidth: 100 + "%",
                maxHeight: 100 + "%",
                maxHeight: ("calc(" + "max(100%, 400px)") + ")",
            },
            src: chosenFileDataUrl.fields[0],
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })))), column(empty(), toList(delay(() => ((encodedImageResult.tag === 0) ? singleton(centerInGrid(toList(delay(() => append(equals(encodedImageResult, new Loadable$1(3)) ? append(singleton(errorIcon()), delay(() => singleton(createElement("p", {
            children: ["Something went wrong. Service may be down."],
        })))) : empty_1(), delay(() => singleton(MuiHelpers_createElement(Button, [["type", "submit"], ["children", "Upload"], ["onClick", (arg00) => {
            const matchValue_2 = fileInput.current;
            if (matchValue_2 != null) {
                const input_4 = matchValue_2;
                void PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    setEncodeResult(new Loadable$1(1));
                    const usrimg = input_4.files[0];
                    const formData = new FormData();
                    formData.append("usrimg", usrimg);
                    formData.append("tryalign", "true");
                    return fetch$(props.EncodeImageApiLocation, ofArray([new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, formData)])).then(((_arg2) => (_arg2.json().then(((_arg3) => {
                        const encodeResult = _arg3;
                        setEncodeResult(new Loadable$1(2, [parse(encodeResult.guid), encodeResult.did_align]));
                        return Promise.resolve();
                    })))));
                }).catch(((_arg4) => {
                    console.log(some("Error in promise!"), _arg4);
                    setEncodeResult(new Loadable$1(3));
                    return Promise.resolve();
                })))));
            }
        }], ["variant", "contained"], ["color", "primary"]])))))))) : ((encodedImageResult.tag === 1) ? singleton(centerInGrid(singleton_1(MuiHelpers_createElement(CircularProgress, [])))) : ((encodedImageResult.tag === 2) ? singleton(props.RenderImgGuid(encodedImageResult.fields[0][0])) : singleton(centerInGrid(toList(delay(() => append(equals(encodedImageResult, new Loadable$1(3)) ? append(singleton(errorIcon()), delay(() => singleton(createElement("p", {
            children: ["Something went wrong. Service may be down."],
        })))) : empty_1(), delay(() => singleton(MuiHelpers_createElement(Button, [["type", "submit"], ["children", "Upload"], ["onClick", (arg00) => {
            const matchValue_2 = fileInput.current;
            if (matchValue_2 != null) {
                const input_4 = matchValue_2;
                void PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    setEncodeResult(new Loadable$1(1));
                    const usrimg = input_4.files[0];
                    const formData = new FormData();
                    formData.append("usrimg", usrimg);
                    formData.append("tryalign", "true");
                    return fetch$(props.EncodeImageApiLocation, ofArray([new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, formData)])).then(((_arg2) => (_arg2.json().then(((_arg3) => {
                        const encodeResult = _arg3;
                        setEncodeResult(new Loadable$1(2, [parse(encodeResult.guid), encodeResult.did_align]));
                        return Promise.resolve();
                    })))));
                }).catch(((_arg4) => {
                    console.log(some("Error in promise!"), _arg4);
                    setEncodeResult(new Loadable$1(3));
                    return Promise.resolve();
                })))));
            }
        }], ["variant", "contained"], ["color", "primary"]]))))))))))))))]))) : (() => {
            throw (new Error("Match failure"));
        })())) : (void 0, empty_1()))), delay(() => append((encodedImageResult.tag === 2) ? ((!encodedImageResult.fields[0][1]) ? singleton(columns(empty(), singleton_1(column(singleton_1(new Option(4, singleton_1(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(2))))), ofArray([MuiHelpers_createElement(Typography, [["color", "error"], ["children", "Unable to align face."]]), MuiHelpers_createElement(Typography, [["color", "textPrimary"], ["children", "When the face isn\u0027t aligned you may get strange results. Try a different image or manually crop it to a square before uploading."]])]))))) : (void 0, empty_1())) : (void 0, empty_1()), delay(() => singleton(columns(singleton_1(new Option_1(5)), ofArray([column(singleton_1(new Option(4, singleton_1(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(2))))), singleton_1(MuiHelpers_createElement(Button, [["children", "Cancel"], ["color", "default"], ["variant", "contained"], ["onClick", close]]))), column(singleton_1(new Option(4, singleton_1(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(3))))), singleton_1(MuiHelpers_createElement(Button, toList(delay(() => append(singleton(["children", "OK"]), delay(() => append(singleton(["color", "primary"]), delay(() => append(singleton(["variant", "contained"]), delay(() => ((encodedImageResult.tag === 3) ? singleton(["disabled", true]) : ((encodedImageResult.tag === 1) ? singleton(["disabled", true]) : ((encodedImageResult.tag === 2) ? append(singleton(["disabled", false]), delay(() => singleton(["onClick", (_arg3_1) => {
            resetState();
            props.OnImageEncoded(encodedImageResult.fields[0][0]);
        }]))) : singleton(["disabled", true])))))))))))))))])))))));
    })))))))])]])])]]);
}

